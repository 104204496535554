import './axios'
import './idle'
import 'bootstrap'
import 'froala-editor/js/plugins/link.min.js'
import 'froala-editor/js/plugins/lists.min.js'
import 'froala-editor/js/languages/ru.js'
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import VModal from 'vue-js-modal'
import NProgress from 'nprogress'
import visibility from 'vue-visibility-change'
import VueTelInput from 'vue-tel-input'
import VueFroala from 'vue-froala-wysiwyg'
import { VueMaskDirective } from 'v-mask'

window.Popper = require('popper.js').default
window.jsonToFormData = require('json-form-data')

NProgress.configure({ showSpinner: false })

Vue.use(VModal, { dynamic: true, injectModalsContainer: true })
Vue.use(Vuelidate)
Vue.use(VueFroala)
Vue.use(visibility)
Vue.use(VueTelInput, {
  placeholder: 'Введите номер телефона',
  dynamicPlaceholder: true,
  mode: 'international',
  onlyCountries: ['RU', 'UA'],
  defaultCountry: 'RU',
  enabledCountryCode: true,
  validCharactersOnly: true
})
Vue.directive('mask', VueMaskDirective)

import getObjects from './base'
import * as types from '@/store/mutation-types'

const state = {
  competitions: JSON.parse(localStorage.getItem('competitions')) || [],
  competitionsUnite: JSON.parse(localStorage.getItem('competitionsUnite')) || []
}

const getters = {
  competitions: state => state.competitions,
  competitionsCheck: state => state.competitions.length > 0,
  competitionsUnite: state => state.competitionsUnite,
  competitionsUniteCheck: state => state.competitionsUnite.length > 0
}

const mutations = {
  [types.SET_COMPETITIONS](state, { objects }) {
    state.competitions = objects
    localStorage.setItem('competitions', JSON.stringify(objects))
  },
  [types.REMOVE_COMPETITIONS](state) {
    state.competitions = []
    localStorage.removeItem('competitions')
  },
  [types.SET_COMPETITIONS_UNITE](state, { objects }) {
    state.competitionsUnite = objects
    localStorage.setItem('competitionsUnite', JSON.stringify(objects))
  },
  [types.REMOVE_COMPETITIONS_UNITE](state) {
    state.competitionsUnite = []
    localStorage.removeItem('competitionsUnite')
  }
}

const actions = {
  async getCompetitions({ commit, getters }) {
    return getObjects(
      commit,
      'competitions/competitions/',
      types.SET_COMPETITIONS,
      getters.competitionsCheck,
      getters.competitions
    )
  },
  async getCompetitionsUnite({ commit, getters }) {
    return getObjects(
      commit,
      'competitions/competitions-unite/',
      types.SET_COMPETITIONS_UNITE,
      getters.competitionsUniteCheck,
      getters.competitionsUnite
    )
  }
}

export default {
  getters,
  state,
  mutations,
  actions
}

<template>
  <li class="sidebar__item w-100">
    <template v-if="item.subitems">
      <span
        class="sidebar__link"
        data-toggle="collapse"
        :data-target="`#${id}`"
      >
        <i :class="item.icon"></i>
        {{ item.name }}
        <i class="fas fa-chevron-down"></i>
      </span>

      <ul :id="id" class="sidebar__nav collapse">
        <li
          v-for="subitem in item.subitems"
          :key="subitem.name"
          class="sidebar__item w-100"
        >
          <sb-link :item="subitem" />
        </li>
      </ul>
    </template>

    <template v-else>
      <sb-link :item="item" />
    </template>
  </li>
</template>

<script>
  import Link from './Link'

  export default {
    props: {
      item: {
        type: Object
      },
      id: {
        type: String
      }
    },
    components: {
      'sb-link': Link
    }
  }
</script>

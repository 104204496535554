import include from '@/utils/router-include'
import documents from './documents'
import statements from './statements'
import contracts from './contracts'

const Settings = () =>
  import(/* webpackChunkName: "lk__settings" */ '@/pages/lk/settings').then(
    m => m.default || m
  )
const Notifications = () =>
  import(
    /* webpackChunkName: "lk__notifications" */ '@/pages/lk/notifications'
  ).then(m => m.default || m)
const Add = () =>
  // import(/* webpackChunkName: "lk__add" */ '@/pages/lk/add').then(
  import(/* webpackChunkName: "lk__add" */ '@/pages/registration/entrant/').then(
    m => m.default || m
  )
const Subjects = () =>
  import(/* webpackChunkName: "lk__subjects" */ '@/pages/lk/subjects').then(
    m => m.default || m
  )

export default [
  { path: '/', redirect: { name: 'lk.statements.list' } },
  { path: '/add/', name: 'add', component: Add },
  { path: '/settings/', name: 'settings', component: Settings },
  { path: '/notifications/', name: 'notifications', component: Notifications },
  { path: '/subjects/', name: 'subjects', component: Subjects },

  ...include('/documents/', documents, 'documents'),
  ...include('/contracts/', contracts, 'contracts'),
  ...include('/statements/', statements, 'statements')
]

const Add = () =>
  import(
    /* webpackChunkName: "admin__contracts__add" */ '@/pages/admin/contracts/add'
  ).then(m => m.default || m)
const Search = () =>
  import(
    /* webpackChunkName: "admin__contracts__search" */ '@/pages/admin/contracts/search'
  ).then(m => m.default || m)

export default [
  { path: '/add/:entrantId/:id', name: 'add', component: Add },
  { path: '/search/', name: 'search', component: Search }
]

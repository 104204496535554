const Rating = () =>
  import(/* webpackChunkName: "rating" */ '@/pages/rating').then(
    m => m.default || m
  )
const RatingCount = () =>
  import(/* webpackChunkName: "rating__count" */ '@/pages/rating/count').then(
    m => m.default || m
  )
const RatingName = () =>
  import(/* webpackChunkName: "rating__fio" */ '@/pages/rating/name').then(
    m => m.default || m
  )
const RatingScore = () =>
  import(/* webpackChunkName: "rating__score" */ '@/pages/rating/score').then(
    m => m.default || m
  )
const RatingQualifications = () =>
  import(
    /* webpackChunkName: "rating__qualifications" */ '@/pages/rating/qualifications'
  ).then(m => m.default || m)
const SelectQualification = () =>
  import(
    /* webpackChunkName: "rating__qualifications" */ '@/pages/rating/selectQualification'
  ).then(m => m.default || m)
const SelectCompetition = () =>
  import(
    /* webpackChunkName: "rating__qualifications" */ '@/pages/rating/selectCompetition'
  ).then(m => m.default || m)
const SelectCompetitionPdf = () =>
  import(
    /* webpackChunkName: "rating__qualifications" */ '@/pages/rating/selectCompetitionPdf'
  ).then(m => m.default || m)
const CompetitionList = () =>
  import(
    /* webpackChunkName: "rating__competition-list" */ '@/pages/rating/competitionList'
  ).then(m => m.default || m)
export default [
  {
    path: '/',
    name: 'index',
    component: Rating,
    meta: { isPublic: true }
  },
  {
    path: '/qualifications/:levelTypeId/:formId/:financingTypeId/',
    name: 'qualifications',
    component: RatingQualifications,
    meta: { isPublic: true }
  },
  {
    path: '/select-qualifications/:branchId/:levelTypeId/:formId/:financingTypeId/',
    name: 'selectQualification',
    component: SelectQualification,
    meta: { isPublic: true }
  },
  {
    path: '/select-competition/:branchId/:levelTypeId/:formId/:financingTypeId/:originalOnly/',
    name: 'selectCompetition',
    component: SelectCompetition,
    meta: { isPublic: true }
  },
  {
    path: '/select-competition-pdf/:branchId/:levelTypeId/:formId/:financingTypeId/:originalOnly/',
    name: 'selectCompetitionPdf',
    component: SelectCompetitionPdf,
    meta: { isPublic: true }
  },
  {
    path: '/count/:branchId/:levelTypeId/:formId/:financingTypeId/',
    name: 'count',
    component: RatingCount,
    meta: { isPublic: true }
  },
  {
    path: '/name/:branchId/:levelTypeId/:formId/:financingTypeId/',
    name: 'name',
    component: RatingName,
    meta: { isPublic: true }
  },
  {
    path: '/score/:levelTypeId/:formId/:financingTypeId/:qualificationId/',
    name: 'score',
    component: RatingScore,
    meta: { isPublic: true }
  },
  {
    path: '/competition-list/:competitionId/',
    name: 'competitionList',
    component: CompetitionList,
    meta: { isPublic: true }
  }
]

export const LOGOUT = 'LOGOUT'
export const LOGIN = 'LOGIN'
export const SET_PAYLOAD = 'SET_PAYLOAD'

export const SET_LOCALE = 'SET_LOCALE'

export const SET_GENDERS = 'SET_GENDERS'
export const REMOVE_GENDERS = 'REMOVE_GENDERS'

export const SET_COLLEGES = 'SET_COLLEGES'
export const REMOVE_COLLEGES = 'REMOVE_COLLEGES'

export const SET_COLLEGE_COMPETITIONS = 'SET_COLLEGE_COMPETITIONS'
export const REMOVE_COLLEGE_COMPETITIONS = 'REMOVE_COLLEGE_COMPETITIONS'

export const SET_EDUCATION_DOCUMENT_TYPES = 'SET_EDUCATION_DOCUMENT_TYPES'
export const REMOVE_EDUCATION_DOCUMENT_TYPES = 'REMOVE_EDUCATION_DOCUMENT_TYPES'

export const SET_IDENTITY_DOCUMENT_TYPES = 'SET_IDENTITY_DOCUMENT_TYPES'
export const REMOVE_IDENTITY_DOCUMENT_TYPES = 'REMOVE_IDENTITY_DOCUMENT_TYPES'

export const SET_REGIONS = 'SET_REGIONS'
export const REMOVE_REGIONS = 'REMOVE_REGIONS'

export const SET_SETTLEMENT_TYPES = 'SET_SETTLEMENT_TYPES'
export const REMOVE_SETTLEMENT_TYPES = 'REMOVE_SETTLEMENT_TYPES'

export const SET_STREET_TYPES = 'SET_STREET_TYPES'
export const REMOVE_STREET_TYPES = 'REMOVE_STREET_TYPES'

export const SET_BENEFIT_TYPES = 'SET_BENEFIT_TYPES'
export const REMOVE_BENEFIT_TYPES = 'REMOVE_BENEFIT_TYPES'

export const SET_ADMISSION_CAMPAIGNS = 'SET_ADMISSION_CAMPAIGNS'
export const REMOVE_ADMISSION_CAMPAIGNS = 'REMOVE_ADMISSION_CAMPAIGNS'

export const SET_EXAM_GROUNDS = 'SET_EXAM_GROUNDS'
export const REMOVE_EXAM_GROUNDS = 'REMOVE_EXAM_GROUNDS'

export const SET_LANGUAGES = 'SET_LANGUAGES'
export const REMOVE_LANGUAGES = 'REMOVE_LANGUAGES'

export const SET_COMPETITIONS = 'SET_COMPETITIONS'
export const REMOVE_COMPETITIONS = 'REMOVE_COMPETITIONS'

export const SET_COMPETITIONS_UNITE = 'SET_COMPETITIONS_UNITE'
export const REMOVE_COMPETITIONS_UNITE = 'REMOVE_COMPETITIONS_UNITE'

export const SET_ADMISSION_DEPARTMENTS = 'SET_ADMISSION_DEPARTMENTS'
export const REMOVE_ADMISSION_DEPARTMENTS = 'REMOVE_ADMISSION_DEPARTMENTS'

export const SET_CONTRACT_TYPES = 'SET_CONTRACT_TYPES'
export const REMOVE_CONTRACT_TYPES = 'REMOVE_CONTRACT_TYPES'

export const SET_FORMS = 'SET_FORMS'
export const REMOVE_FORMS = 'REMOVE_FORMS'

export const SET_LEVEL_TYPES = 'SET_LEVEL_TYPES'
export const REMOVE_LEVEL_TYPES = 'REMOVE_LEVEL_TYPES'

export const SET_FINANCING_TYPES = 'SET_FINANCING_TYPES'
export const REMOVE_FINANCING_TYPES = 'REMOVE_FINANCING_TYPES'

export const SET_EXAM_WAYS = 'SET_EXAM_WAYS'
export const REMOVE_EXAM_WAYS = 'REMOVE_EXAM_WAYS'

export const SET_SUBJECTS = 'SET_SUBJECTS'
export const REMOVE_SUBJECTS = 'REMOVE_SUBJECTS'

export const SET_SUBJECTS_UNITE = 'SET_SUBJECTS_UNITE'
export const REMOVE_SUBJECTS_UNITE = 'REMOVE_SUBJECTS_UNITE'

export const SET_BRANCHES = 'SET_BRANCHES'
export const REMOVE_BRANCHES = 'REMOVE_BRANCHES'

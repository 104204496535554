import axios from 'axios'
import * as types from '../mutation-types'

export const state = {
  payload: JSON.parse(localStorage.getItem('payload')) || null
}

export const getters = {
  payload: state => state.payload,
  user: state => (state.payload ? state.payload : null),
  check: state => state.payload !== null,
  photo: (state, getters) => {
    if (!getters.check) {
      return null
    }
    const user = getters.user
    return user.photo
  },
  fullName: (state, getters) => {
    if (!getters.check) {
      return ''
    }
    const user = getters.user
    if (user.surname) {
      if (user.patronymic) {
        return `${user.surname} ${user.name} ${user.patronymic}`
      }
      return `${user.surname} ${user.name}`
    }
    return user.username
  },
  role: (state, getters) => {
    if (!getters.check) {
      return ''
    }
    if (getters.checkAdmin) {
      return 'Администратор:'
    }
    if (getters.checkStaff) {
      return 'Сотрудник:'
    }
    if (getters.checkOperator) {
      return 'Оператор:'
    }
    return 'Абитуриент:'
  },
  checkAdmin: (state, getters) => getters.user.isAdmin,
  isStaff: (state, getters) => getters.user.isStaff,
  checkStaff: (state, getters) => getters.user.isStaff || getters.checkAdmin,
  checkOperator: (state, getters) =>
    getters.user ? getters.user.isOperator || getters.checkStaff : false,
  checkEntrant: (state, getters) =>
    getters.user ? getters.check && getters.user.isEntrant : false,
  checkFinishStepAdd: (state, getters) => getters.user.isFinishStepAdd,
  icon: (state, getters) => {
    const user = getters.user

    if (user) {
      if (user.isAdmin) {
        return 'fas fa-user-cog'
      }
      if (user.isStaff) {
        return 'fas fa-user-tie'
      }
    }
    return 'fas fa-user'
  },
  checkRole: (state, getters) => role => {
    const user = getters.user

    if (user) {
      if (user.isAdmin || role === 'all') {
        return true
      }
      if (user.isStaff && role === 'staff') {
        return true
      }
    }
    return false
  },
  checkRegistrationType: (state, getters) => getters.user.registrationType
}

export const mutations = {
  [types.SET_PAYLOAD](state, { payload }) {
    state.payload = payload
  },
  [types.LOGIN](state, { payload }) {
    state.payload = payload
    localStorage.setItem('payload', JSON.stringify(payload))
  },

  [types.LOGOUT](state) {
    state.payload = null
    localStorage.removeItem('payload')
  }
}

export const actions = {
  confirmEmail({ commit }, { email, key }) {
    return new Promise((resolve, reject) => {
      axios
        .post('auth/confirmed/', { email, key })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  registration({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      axios
        .post('auth/registration/', userInfo)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  login({ commit }, { username, password }) {
    return new Promise((resolve, reject) => {
      axios
        .post('auth/login/', { username, password })
        .then(response => {
          const payload = response.data
          commit(types.LOGIN, { payload })
          resolve(response)
        })
        .catch(error => {
          commit(types.LOGOUT)
          reject(error)
        })
    })
  },

  logout({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('auth/logout/')
      commit(types.LOGOUT)
      resolve({})
    })
  },

  info({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get('auth/info/')
        .then(response => {
          const payload = response.data
          commit(types.LOGIN, { payload })
          resolve(response)
        })
        .catch(error => {
          commit(types.LOGOUT)
          reject(error)
        })
    })
  }
}

import getObjects from './base'
import * as types from '@/store/mutation-types'

const state = {
  regions: JSON.parse(localStorage.getItem('regions')) || [],
  settlementTypes: JSON.parse(localStorage.getItem('settlementTypes')) || [],
  streetTypes: JSON.parse(localStorage.getItem('streetTypes')) || []
}

const getters = {
  regions: state => state.regions,
  regionsCheck: state => state.regions.length > 0,

  settlementTypes: state => state.settlementTypes,
  settlementTypesCheck: state => state.settlementTypes.length > 0,

  streetTypes: state => state.streetTypes,
  streetTypesCheck: state => state.streetTypes.length > 0
}

const mutations = {
  [types.SET_REGIONS](state, { objects }) {
    state.regions = objects
    localStorage.setItem('regions', JSON.stringify(objects))
  },
  [types.REMOVE_REGIONS](state) {
    state.regions = []
    localStorage.removeItem('regions')
  },

  [types.SET_SETTLEMENT_TYPES](state, { objects }) {
    state.settlementTypes = objects
    localStorage.setItem('settlementTypes', JSON.stringify(objects))
  },
  [types.REMOVE_SETTLEMENT_TYPES](state) {
    state.settlementTypes = []
    localStorage.removeItem('settlementTypes')
  },

  [types.SET_STREET_TYPES](state, { objects }) {
    state.documentTypes = objects
    localStorage.setItem('streetTypes', JSON.stringify(objects))
  },
  [types.REMOVE_STREET_TYPES](state) {
    state.documentTypes = []
    localStorage.removeItem('streetTypes')
  }
}

const actions = {
  async getRegions({ commit, getters }) {
    return getObjects(
      commit,
      'address/regions/',
      types.SET_REGIONS,
      getters.regionsCheck,
      getters.regions
    )
  },

  async getSettlementTypes({ commit, getters }) {
    return getObjects(
      commit,
      'address/settlement-types/',
      types.SET_SETTLEMENT_TYPES,
      getters.settlementTypesCheck,
      getters.settlementTypes
    )
  },

  async getStreetTypes({ commit, getters }) {
    return getObjects(
      commit,
      'address/street-types/',
      types.SET_STREET_TYPES,
      getters.streetTypesCheck,
      getters.streetTypes
    )
  }
}

export default {
  getters,
  state,
  mutations,
  actions
}

<template>
  <nav class="navbar navbar-dark sticky-top shadow">
    <router-link
      :to="{ name: 'home' }"
      class="navbar-brand"
    >
      <img
        src="/static/images/rsue_logo.svg"
        class="logo"
        alt="Logo"
      />
      <div class="navbar__title">
        {{ title }} <br />
        <span class="navbar__year">
          {{ year }}
        </span>
      </div>
    </router-link>
    <button
      v-if="showBtn"
      class="navbar-toggler"
      type="button"
      @click="btnClick"
    >
      <i class="fas fa-bars"></i>
    </button>
    <div class="dropdown d-flex ml-auto my-2 my-lg-0 position-relative">
      <button
        class="btn btn-secondary navbar__btn"
        type="button"
        data-toggle="dropdown"
      >
        <img
          v-if="photo"
          :src="photo"
          class="w-100"
          alt="Фото"
        />
        <i
          v-else
          :class="roleIcon"
        ></i>
      </button>
      <i
        class="mdi mdi-24px mdi-menu-down text-white position-absolute"
        style="bottom: -13px;right: -7px;z-index: -1"
      ></i>
      <div class="dropdown-menu dropdown-menu-right">
        <strong class="dropdown-item-text">
          {{ role }}
        </strong>
        <span class="dropdown-item-text">
          {{ fullName }}
        </span>
        <div class="dropdown-divider"></div>
        <template v-if="isEntrant">
          <a
            :href='entrantInstructionUrl'
            class="dropdown-item"
            target="_blank"
          >
            <i class="mdi mdi-book-search"></i> Документация
          </a>
          <a
            href="https://rsue.ru/abitur/"
            class="dropdown-item"
            target="_blank"
          >
            <i class="mdi mdi-book-information-variant"></i> Правила приема
          </a>
          <a
            href="http://abit.rsue.ru/"
            class="dropdown-item"
            target="_blank"
          >
            <i class="mdi mdi-school"></i> Абитуриенту
          </a>
        </template>
        <template v-else>
          <a
            :href="operatorInstructionUrl"
            target="_blank"
            class="dropdown-item"
          >
            <i class="mdi mdi-book-search"></i> Документация
          </a>
        </template>
        <div class="dropdown-divider"></div>
        <button
          class="dropdown-item"
          type="button"
          @click="btnExit"
        >
          <i class="fas fa-sign-out-alt"></i> {{ $t('logout') }}
        </button>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import authService from '@/services/auth'

export default {
  name: 'navbar',

  props: {
    showBtn: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      year: window.config.YEAR,
      operatorInstructionUrl: window.config.operatorInstructionUrl,
      entrantInstructionUrl: window.config.entrantInstructionUrl
    }
  },
  computed: {
    ...mapGetters({
      photo: 'auth/photo',
      fullName: 'auth/fullName',
      role: 'auth/role',
      isEntrant: 'auth/checkEntrant'
    }),
    title: function () {
      const { appName } = window.config
      return appName
    },
    roleIcon: function () {
      return this.$store.getters['auth/icon']
    }
  },
  methods: {
    btnClick: function (e) {
      this.$emit('click')
    },
    btnExit: function (e) {
      authService.logout()
    }
  }
}
</script>

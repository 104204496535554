const Reports = () =>
  import(/* webpackChunkName: "admin__reports" */ '@/pages/admin/reports').then(
    m => m.default || m
  )
const FIO = () =>
  import(
    /* webpackChunkName: "admin__reports__fio" */ '@/pages/admin/reports/fio'
  ).then(m => m.default || m)
const Registry = () =>
  import(
    /* webpackChunkName: "admin__reports__registry" */ '@/pages/admin/reports/registry'
  ).then(m => m.default || m)
const SpecialRight = () =>
  import(
    /* webpackChunkName: "admin__reports__special-right" */ '@/pages/admin/reports/special-right'
  ).then(m => m.default || m)
const SpecialQuota = () =>
  import(
    /* webpackChunkName: "admin__reports__special-right" */ '@/pages/admin/reports/special-quota'
  ).then(m => m.default || m)
const Foreigner = () =>
  import(
    /* webpackChunkName: "admin__reports__foreigner" */ '@/pages/admin/reports/foreigner'
  ).then(m => m.default || m)
const IndividualAchievement = () =>
  import(
    /* webpackChunkName: "admin__reports__individual-achievement" */ '@/pages/admin/reports/individual-achievement'
  ).then(m => m.default || m)
const Exam = () =>
  import(
    /* webpackChunkName: "admin__reports__exam" */ '@/pages/admin/reports/exam'
  ).then(m => m.default || m)

const Payment = () =>
  import(
    /* webpackChunkName: "admin__reports__payment" */ '@/pages/admin/reports/payment'
  ).then(m => m.default || m)

const Count = () =>
  import(
    /* webpackChunkName: "admin__reports__count" */ '@/pages/admin/reports/count'
  ).then(m => m.default || m)

const TargetRight = () =>
  import(
    /* webpackChunkName: "admin__reports__target-right" */ '@/pages/admin/reports/target-right'
  ).then(m => m.default || m)

const PaymentList = () =>
  import(
    /* webpackChunkName: "admin__reports__payment-list" */ '@/pages/admin/reports/payment-list'
  ).then(m => m.default || m)

const CountRegions = () =>
  import(
    /* webpackChunkName: "admin__reports__count-regions" */ '@/pages/admin/reports/count-regions'
  ).then(m => m.default || m)

const CountCountry = () =>
  import(
    /* webpackChunkName: "admin__reports__count-country" */ '@/pages/admin/reports/count-country'
  ).then(m => m.default || m)

const CountProfiles = () =>
  import(
    /* webpackChunkName: "admin__reports__count-profiles" */ '@/pages/admin/reports/count-profiles'
  ).then(m => m.default || m)

const EverydayReport = () =>
  import(
        /* webpackChunkName: "admin__reports__exam" */ '@/pages/admin/reports/everyday-report'
  ).then(m => m.default || m)

const AchievementsList = () =>
  import(
    '@/pages/admin/reports/achievements-list'
  ).then(m => m.default || m)

const OriginalsList = () =>
  import(
    '@/pages/admin/reports/originals-list'
  ).then(m => m.default || m)

// const MinimalPoints = () =>
//   import(
//     '@/pages/admin/reports/minimal-points'
//   ).then(m => m.default || m)

export default [
  { path: '/', name: 'list', component: Reports },
  { path: '/fio', name: 'fio', component: FIO },
  { path: '/registry', name: 'registry', component: Registry },
  { path: '/special-right', name: 'specialRight', component: SpecialRight },
  { path: '/target-right', name: 'targetRight', component: TargetRight },
  { path: '/foreigners', name: 'foreigners', component: Foreigner },
  { path: '/exams', name: 'exam', component: Exam },
  { path: '/payments', name: 'payment', component: Payment },
  { path: '/payment-list', name: 'paymentList', component: PaymentList },
  { path: '/count', name: 'count', component: Count },
  { path: '/count-regions', name: 'countRegions', component: CountRegions },
  { path: '/count-country', name: 'countCountry', component: CountCountry },
  { path: '/count-profiles', name: 'countProfiles', component: CountProfiles },
  { path: '/everyday-report', name: 'everydayReport', component: EverydayReport },
  { path: '/achievements-list', name: 'achievementsList', component: AchievementsList },
  { path: '/special-quota', name: 'specialQuota', component: SpecialQuota },
  { path: '/originals-list', name: 'originalsList', component: OriginalsList },
  {
    path: '/individual-achievement',
    name: 'individualAchievement',
    component: IndividualAchievement
  }
]

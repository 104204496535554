const List = () =>
  import(/* webpackChunkName: "lk__statements" */ '@/pages/lk/statements').then(
    m => m.default || m
  )
const Add = () =>
  import(
    /* webpackChunkName: "lk__statements__add" */ '@/pages/statements'
  ).then(m => m.default || m)
const Edit = () =>
  import(
    /* webpackChunkName: "lk__statements__edit" */ '@/pages/lk/statements/edit'
  ).then(m => m.default || m)

export default [
  { path: '/', name: 'list', component: List },
  { path: '/add/', name: 'add', component: Add },
  { path: '/edit/:id', name: 'edit', component: Edit }
]

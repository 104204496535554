import store from '@/store'
import authService from '@/services/auth'

export default (to, from, next) => {
  if (store.getters['auth/check']) {
    next(authService.getRoute())
  } else {
    next()
  }
}

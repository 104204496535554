<template>
  <div>
    <transition name="page" mode="out-in">
      <component v-if="layout" :is="layout" />
    </transition>
  </div>
</template>

<script>
  import authService from '@/services/auth'
import Swal from 'sweetalert2/dist/sweetalert2'
import visibility from 'vue-visibility-change'

  const requireContext = require.context('@/layouts', false, /.*\.vue$/)

  const layouts = requireContext
    .keys()
    .map(file => [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file)])
    .reduce((components, [name, component]) => {
      components[name] = component.default || component
      return components
    }, {})

  export default {
    data: () => ({
      timerId: null,
      layout: null,
      defaultLayout: 'default'
    }),

    onIdle() {
      const { getters } = this.$store

      if (getters['auth/check'] && !visibility.hidden()) {
        let timerInterval

        const onBeforeOpen = () => {
          Swal.showLoading()
          timerInterval = setInterval(() => {
            const content = Swal.getContent()
            if (content) {
              const b = content.querySelector('b')
              if (b) {
                b.textContent = Math.ceil(Swal.getTimerLeft() / 1000)
              }
            }
          }, 100)
        }

        const onClose = () => {
          clearInterval(timerInterval)
        }

        Swal.fire({
          icon: 'warning',
          title: 'Сеанс истекает',
          html: 'Выход из личного кабинета через <b></b> секунд',
          timer: 10000,
          timerProgressBar: true,
          onBeforeOpen: onBeforeOpen,
          onClose: onClose
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            authService.logout(true)
          }
        })
      }
    },

    onActive() {
      const { getters } = this.$store

      if (getters['auth/check']) {
        Swal.close()
      }
    },
    mounted() {
      const { getters } = this.$store

      if (getters['auth/check']) {
        authService.info()
      }
    },
    metaInfo() {
      const { appName } = window.config

      return {
        title: appName,
        titleTemplate: `%s | ${appName}`
      }
    },

    methods: {
      setLayout(layout) {
        if (!layout || !layouts[layout]) {
          layout = this.defaultLayout
        }

        this.layout = layouts[layout]
      }
    }
  }
</script>

<style lang="sass">
  @import '~style/app'
</style>

import store from '@/store'
import axios from 'axios'
// import router from '@/router'
import authService from '@/services/auth'
import Cookies from 'js-cookie'
import NProgress from 'nprogress'
import swal from 'sweetalert2/dist/sweetalert2'
import i18n from './i18n'

axios.interceptors.request.use(request => {
  NProgress.start()

  request.headers.common['X-CSRFToken'] = Cookies.get('csrftoken')

  const locale = store.getters['lang/locale']

  if (locale) {
    request.headers.common['Accept-Language'] = locale
  }

  request.baseURL = '/api/v1'
  return request
})

axios.interceptors.response.use(
  response => {
    NProgress.done()
    return response
  },
  error => {
    const status = error.response ? error.response.status : error.response
    NProgress.done()

    if (
      [401, 403].includes(status) &&
      error.response.config.url !== 'auth/logout/'
    ) {
      authService.logout(true)
    }

    if (status >= 500) {
      swal.fire({
        icon: 'error',
        title: i18n.t('error_alert_title'),
        text: i18n.t('error_alert_text'),
        reverseButtons: true,
        confirmButtonText: i18n.t('ok'),
        cancelButtonText: i18n.t('cancel')
      })
    }

    // if (status === 404 && !error.config.ignoreNotFoundPage) {
    //   router.push({ name: '404' })
    // }

    return Promise.reject(error)
  }
)

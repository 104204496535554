<template>
  <router-link
    v-if="item.isNotReload"
    :to="item.to"
    exact-active-class="active"
    class="sidebar__link"
  >
    <i :class="item.icon"></i>
    {{ item.name }}
  </router-link>
  <a
    v-else
    class="sidebar__link"
    :href="item.to"
    :target="item.to !== '#' ? '_blank' : false"
  >
    <i :class="item.icon"></i>
    {{ item.name }}
  </a>
</template>

<script>
  export default {
    props: {
      item: {
        type: Object
      }
    }
  }
</script>

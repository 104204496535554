<template>
  <button
    :data-placement="tooltipPosition"
    :title="title"
    :type="type ? type : 'button'"
    :class="`btn ${background ? background : 'btn-info'}`"
    @click="btnClick"
    :disabled="disabled"
    ref="button"
  >
    <i v-if="icon" :class="icon"></i>
    <span v-if="text"> {{ text }}</span>
  </button>
</template>

<script>
  import $ from 'jquery'

  export default {
    name: 'app-button',
    props: [
      'icon',
      'type',
      'text',
      'disabled',
      'title',
      'tooltipPosition',
      'background'
    ],
    mounted() {
      const btn = this.$refs.button
      $(btn)
        .tooltip({
          html: true,
          trigger: 'hover'
        })
        .on('click mousedown mouseup', function() {
          $(btn).tooltip('hide')
        })
    },
    updated() {
      const btn = this.$refs.button
      $(btn).tooltip('dispose')
      $(btn)
        .tooltip({
          html: true,
          trigger: 'hover'
        })
        .on('click mousedown mouseup', function() {
          $(btn).tooltip('hide')
        })
    },
    beforeDestroy() {
      const btn = this.$refs.button
      $(btn).tooltip('dispose')
    },
    methods: {
      btnClick: function(e) {
        if (!this.disabled) {
          this.$emit('click')
        }
      }
    }
  }
</script>

<template>
  <span
    v-if="!to"
    :title="title"
    :class="btnClass"
    :disabled="disabled"
    ref="button"
  >
    <i :class="icon"></i> {{ text }}
  </span>
  <router-link
    v-else-if="isRefresh"
    :title="title"
    :to="to"
    :class="btnClass"
    :disabled="disabled"
    ref="button"
  >
    <i :class="icon"></i> {{ text }}
  </router-link>
  <a
    v-else
    :title="title"
    :href="to"
    :class="btnClass"
    :disabled="disabled"
    target="_blank"
    ref="button"
  >
    <i :class="icon"></i> {{ text }}
  </a>
</template>

<script>
import Vue from 'vue'
import $ from 'jquery'

export default {
  name: 'app-button-link',
  props: ['to', 'icon', 'title', 'disabled', 'isRefresh', 'text'],
  computed: {
    btnClass() {
      return {
        'btn btn-info text-white': true,
        disabled: this.disabled
      }
    }
  },
  mounted() {
    const btn =
      this.$refs.button instanceof Vue
        ? this.$refs.button.$el
        : this.$refs.button
    $(btn)
      .tooltip({
        html: true,
        trigger: 'hover'
      })
      .on('click mousedown mouseup', function () {
        $(btn).tooltip('hide')
      })
  },
  updated() {
    const btn =
      this.$refs.button instanceof Vue
        ? this.$refs.button.$el
        : this.$refs.button
    $(btn).tooltip('dispose')
    $(btn)
      .tooltip({
        html: true,
        trigger: 'hover'
      })
      .on('click mousedown mouseup', function () {
        $(btn).tooltip('hide')
      })
  },
  beforeDestroy() {
    const btn =
      this.$refs.button instanceof Vue
        ? this.$refs.button.$el
        : this.$refs.button
    $(btn).tooltip('dispose')
  }
}
</script>

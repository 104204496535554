import include from '@/utils/router-include'
import admin from './admin'
import rating from './rating'
import lk from './lk'

const Home = () =>
  import(/* webpackChunkName: "home" */ '@/pages/home').then(
    m => m.default || m
  )
const NotFound = () =>
  import(/* webpackChunkName: "404" */ '@/pages/errors/404').then(
    m => m.default || m
  )
const Login = () =>
  import(/* webpackChunkName: "login" */ '@/pages/auth/login').then(
    m => m.default || m
  )
const PasswordReset = () =>
  import(/* webpackChunkName: "passwordReset" */ '@/pages/auth/passwordReset').then(
    m => m.default || m
  )
const PasswordResetConfirm = () =>
  import(/* webpackChunkName: "passwordResetConfirm" */ '@/pages/auth/passwordResetConfirm').then(
    m => m.default || m
  )
const Protected = () =>
  import(/* webpackChunkName: "protected" */ '@/pages/errors/protected').then(
    m => m.default || m
  )
const Registration = () =>
  import(
    /* webpackChunkName: "registration" */ '@/pages/auth/registration'
  ).then(m => m.default || m)
const Confirm = () =>
  import(/* webpackChunkName: "confirm" */ '@/pages/auth/confirm').then(
    m => m.default || m
  )

export default [
  ...include('/lk/', lk, 'lk'),
  ...include('/admin/', admin, 'admin'),
  ...include('/rating/', rating, 'rating'),

  { path: '/', name: 'home', component: Home, meta: { isPublic: true } },
  {
    path: '/login/',
    name: 'login',
    component: Login,
    meta: { isPublic: true }
  },
  {
    path: '/registration/',
    name: 'registration',
    component: Registration,
    meta: { isPublic: true }
  },
  {
    path: '/protected/',
    name: 'protected',
    component: Protected,
    meta: { isPublic: true }
  },
  {
    path: '/confirm/',
    name: 'confirm',
    component: Confirm,
    meta: { isPublic: true }
  },
  {
    path: '/password-reset/',
    name: 'password-reset',
    component: PasswordReset,
    meta: { isPublic: true }
  },
  {
    path: '/password-reset/confirm/',
    name: 'password-reset-confirm',
    component: PasswordResetConfirm,
    meta: { isPublic: true }
  },
  { path: '*', name: '404', component: NotFound, meta: { isPublic: true } }
]

<template>
  <div>
    <label
      class="pl-1 label"
      :for="uuid"
      v-if="!hideLabel"
    >
      {{ name }}
      <span
        v-if="required"
        class="text-danger"
      >*</span>
    </label>
    <div class="custom-file">
      <input
        type="file"
        :class="classInput"
        :id="uuid"
        :disabled="disabled"
        @change="change"
        :accept="type"
        ref="input"
      />
      <label
        class="custom-file-label"
        :for="uuid"
      >
        <i
          v-if="icon"
          :class="classIcon"
        ></i>
        {{ value ? value.name : 'Выберите файл' }}
      </label>
      <div
        v-if="url"
        class="pl-1 mb-1"
      >
        <a
          :href="url"
          target="_blank"
        >Скачать документ</a>
      </div>
      <div
        v-if="!url"
        class="empty-feedback"
      >Нет ошибок</div>
      <div class="mb-1 pl-1 invalid-feedback">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import { v1 as uuidv1 } from 'uuid'

export default {
  name: 'app-fileinput',
  props: {
    value: {},
    name: String,
    hideLabel: Boolean,
    valid: {
      type: Boolean,
      default: false
    },
    useValid: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: String,
    url: String,
    error: {
      type: String,
      default: 'Обязательное поле'
    }
  },
  computed: {
    icon() {
      if (this.value) {
        const type = this.value.type
        if (type === 'application/pdf') {
          return 'mdi-file-pdf'
        } else if (type === 'image/png' || type === 'image/jpeg') {
          return 'mdi-file-image'
        } else {
          return 'mdi-file'
        }
      }
      return null
    },
    classIcon() {
      return ['mdi', this.icon]
    },
    uuid: () => uuidv1(),
    classInput() {
      return {
        'custom-file-input': true,
        'is-valid': this.useValid && this.valid && !this.disabled,
        'is-invalid': this.useValid && !this.valid
      }
    }
  },
  methods: {
    change: function (e) {
      this.$emit('input', e.target.files[0])
    }
  }
}
</script>

<template>
  <div>
    <label v-if="!hideLabel" :for="uuid" class="pl-1 label">{{ name }}</label>
    <input
      :style="inputStyle"
      type="number"
      :class="classInput"
      :id="uuid"
      :placeholder="name"
      :value="value"
      @input="input"
      :disabled="disabled"
    />
  </div>
</template>

<script>
  import { v1 as uuidv1 } from 'uuid'

  export default {
    name: 'app-numbox',
    props: {
      value: Number,
      name: String,
      hideLabel: Boolean,
      valid: Boolean,
      useValid: Boolean,
      placeholder: String,
      required: Boolean,
      disabled: Boolean,
      inputStyle: String,
      step: {
        type: Number,
        default: 1
      }
    },
    computed: {
      uuid: () => uuidv1(),
      classInput() {
        const notEmpty = !this.value && this.value !== 0
        return {
          'form-control': true,
          'is-valid':
            this.useValid && this.valid && !notEmpty && !this.disabled,
          'is-invalid': this.useValid && !this.valid
        }
      }
    },
    methods: {
      input(e) {
        if (!e.target.value) {
          this.$emit('input', null)
          return
        }
        this.$emit('input', parseInt(e.target.value))
      }
    }
  }
</script>

<template>
  <div>
    <label
      v-if="!hideLabel"
      class="pl-1 label"
    >
      {{ name }}
      <span
        v-if="required"
        class="text-danger"
      >*</span>
    </label>
    <select
      :class="classSelect"
      @change="change"
      :disabled="disabled"
    >
      <option
        v-if="emptyValue"
        :value="null"
        :selected="value ? false : true"
      >
        {{ emptyValue }}
      </option>
      <option
        v-for="item in options"
        :value="item[trackBy]"
        :key="item[trackBy]"
        :selected="selectedOption(item)"
      >
        {{ item[label] }}
      </option>
    </select>
    <template v-if="useValid">
      <div class="empty-feedback">Нет ошибок</div>
      <div class="mb-1 pl-1 invalid-feedback">
        {{ error }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'app-select',
  props: {
    name: String,
    valid: Boolean,
    useValid: Boolean,
    required: Boolean,
    options: Array,
    label: String,
    trackBy: String,
    emptyValue: String,
    disabled: Boolean,
    hideLabel: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: 'Обязательное поле'
    },
    value: {}
    // value: [Number, Object]
  },
  computed: {
    classSelect() {
      return {
        'custom-select': true,
        'is-valid': this.useValid && this.valid && !this.disabled,
        'is-invalid': this.useValid && !this.valid
      }
    }
  },
  created() {
    // if (Number.isInteger(this.value)) {
    //   const newValue = this.options.filter(s => s.id === this.value)[0]
    //   this.$emit('input', newValue)
    // }
  },
  methods: {
    selectedOption(option) {
      const isN = (n) => { return /^-?[\d.]+(?:e-?\d+)?$/.test(n) }
      if (isN(this.value)) {
        return option[this.trackBy] === this.value
      } else if (this.value) {
        return option[this.trackBy] === this.value[this.trackBy]
      }
      return false
    },
    change: function (e) {
      const isN = (n) => { return /^-?[\d.]+(?:e-?\d+)?$/.test(n) }
      const value = e.target.value
      let item = null
      if (isN(value)) {
        // console.log(1)
        item =
          this.options.find(x => x[this.trackBy].toString() === value) || null
        this.$emit('input', item)
      } else {
        // console.log(2)

        item =
          this.options.find(x => x[this.trackBy].toString() === value[this.trackBy]) || null
        this.$emit('input', item)
      }
    }
  }
}
</script>
